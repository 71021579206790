body {
	font-family: "Open Sans", "Roboto", "SF Pro Text", "Helvetica"
}

.separator {
    display: flex;
    align-items: center;
    text-align: start;
}

.separator::before {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
}

.separator::after {
    content: '';
    flex: 8;
    border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
    margin-right: .25em;
}

.separator:not(:empty)::after {
    margin-left: .25em;
}
