ul.link { 
  padding: 0; 
  list-style: none; 
  display: table;
  text-align: center;
}
ul.link li { 
  display: table-cell; 
  position: relative; 
  padding: 15px 0;
}

ul.link a {
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}

ul.link a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

ul.link a:hover:after { 
  width: 100%; 
  left: 0; 
}

